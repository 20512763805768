import React from "react";
import { graphql, Link } from "gatsby";
import {
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  CloseTwoTone,
} from "@material-ui/icons";
import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const reducer = (imageNumber) => (state, { type }) => {
  switch (type) {
    case "NEXT_IMAGE":
      if (state === imageNumber - 1) return 0;
      return state + 1;
    case "PREVIOUS_IMAGE":
      if (state === 0) return imageNumber - 1;
      return state - 1;
  }
};

const Wohnung = ({ className, pageContext, data }) => {
  const images = data.galleryImages.nodes.map((node) => ({
    alt: node.alt,
    ...getImage(node),
  }));

  const [imageIndex, dispatch] = React.useReducer(reducer(images.length), 0);

  return (
    <div
      className={clsx(className, "grid h-screen bg-warmGray-200")}
      style={{ gridTemplateRows: "max-content 1fr" }}
    >
      <div className="flex justify-between py-4 items-center">
        <div className="ml-3 lg:ml-8 pl-4 border-l-4 border-orange-700">
          <h1 className="text-xl lg:text-4xl text-warmGray-700 font-semibold ">
            {pageContext.title}
          </h1>
          <subheading className="text-sm lg:text-lg mt-4">
            {images[imageIndex].alt}
          </subheading>
        </div>
        <Link
          to="/"
          className="bg-orange-300 rounded-full p-1 lg:p-3 mr-4 shadow-lg z-10 text-orange-900"
        >
          <CloseTwoTone fontSize="normal" />
        </Link>
      </div>
      <button
        onClick={() => dispatch({ type: "PREVIOUS_IMAGE" })}
        className="absolute bottom-6 lg:bottom-auto lg:top-1/2 left-4 lg:left-10 z-10 p-2 lg:p-3 rounded-full bg-orange-300 shadow-lg text-orange-900"
      >
        <ArrowBackIosTwoTone fontSize="normal" />
      </button>
      <GatsbyImage
        imgStyle={{ objectFit: "contain" }}
        className="w-full h-full"
        alt={images[imageIndex].alt}
        key={images[imageIndex].src}
        aspectRatio={images[imageIndex].aspectRatio}
        image={images[imageIndex]}
      />
      <button
        onClick={() => dispatch({ type: "NEXT_IMAGE" })}
        className="absolute bottom-6 lg:bottom-auto lg:top-1/2 right-4 lg:right-10 p-2 lg:p-3 rounded-full bg-orange-300 shadow-lg text-orange-900"
      >
        <ArrowForwardIosTwoTone fontSize="normal" />
      </button>
    </div>
  );
};

export const query = graphql`
  query($regex: String!) {
    galleryImages: allFile(filter: { relativePath: { regex: $regex } }) {
      nodes {
        alt: name
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 70)
        }
      }
    }
  }
`;

export default Wohnung;
